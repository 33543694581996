<template>
  <div>
    <!-- <div class="vhgf">
      <div class="container">
        <marquee
          behavior="left"
          direction="2"
          onmouseover="this.stop()"
          onmouseout="this.start()"
          class="rounded px-1 pt-2 pb-1 mb-0 mr-1 marqueemain"
        >เปิดแล้ว เว็บเเทงหวยอันดับ 1 ของไทย มาพร้อมคาสิโน
          เเละเกมส์ต่างๆมากมาย ที่นี่ HUAY PLUS+ เปิดให้บริการฝาก-ถอน ตลอด 24
          ชั่วโมง
        </marquee>
      </div>
    </div> -->

    <div class="container mt-1">
      <b-row>
        <b-col
          md="3"
          class="p-0"
        />
        <b-col
          md="6"
          class="p-0"
        >
          <div class="header-bg">
            <div class="container mb-2">
              <!-- <div class="mb-2">
                <b-carousel
                  id="carousel-interval"
                  no-hover-pause
                  controls
                  indicators
                  :interval="5000"
                >
                  <b-carousel-slide img-src="/ais/1.jpg" />
                  <b-carousel-slide img-src="/ais/2.jpg" />
                </b-carousel>
              </div> -->

              <div>
                <b-row>
                  <b-col
                    cols="1"
                    class="text-right"
                  >
                    <i class="fas fa-volume-up" />
                  </b-col>

                  <b-col cols="11">
                    <marquee
                      direction="left"
                      onmouseover="this.stop()"
                      onmouseout="this.start()"
                      truespeed="slow"
                    >เปิดแล้ว เว็บเเทงหวยอันดับ 1 ของไทย มาพร้อมคาสิโน
                      เเละเกมส์ต่างๆมากมาย ที่นี่ มหาล๊อตโต
                      เปิดให้บริการฝาก-ถอน ตลอด 24 ชั่วโมง
                    </marquee>
                  </b-col>
                </b-row>
              </div>

              <div class="bg-balance">
                <!-- mobile -->
                <b-row class="d-block d-md-none">
                  <b-col
                    cols="12"
                    class="balance text-center"
                  >
                    <p>
                      <i class="fas fa-wallet" /> ยอดเงินทั้งหมด
                      <i
                        class="fas fa-sync"
                        @click.prevent="ShowWallet"
                      />
                    </p>
                    <h3 class="text-white font-large-2">
                      {{
                        Commas(this.$store.state.appConfig.UserData.MainWallet)
                      }}
                      บาท
                    </h3>
                  </b-col>
                  <!-- <div class="text-center">
            <small

            ><i class="fas fa-sync" /> ข้อมูล ณ เวลา 18:00 น.</small>
          </div> -->
                </b-row>
                <!-- mobile -->
                <b-row class="d-none d-md-flex">
                  <b-col
                    md="12"
                    class="balance"
                  >
                    <p>
                      <i class="fas fa-wallet" /> ยอดเงินทั้งหมด
                      <i
                        class="fas fa-sync cursor-pointer"
                        @click.prevent="ShowWallet"
                      />
                    </p>
                    <h3 class="text-white">
                      {{
                        Commas(this.$store.state.appConfig.UserData.MainWallet)
                      }}
                      บาท
                    </h3>
                  </b-col>
                </b-row>
              </div>

              <b-row>
                <b-col cols="6">
                  <router-link :to="{ name: 'deposit' }">
                    <b-button
                      variant="gradient-primary"
                      block
                      style="color: #000"
                    >
                      <i class="fas fa-plus-circle" /> ฝากเครดิต
                    </b-button>
                  </router-link>
                </b-col>
                <b-col cols="6">
                  <router-link :to="{ name: 'withdraw' }">
                    <b-button
                      variant="gradient-secondary"
                      block
                      style="color: #fff"
                    >
                      <i class="fas fa-minus-circle" /> ถอนเครดิต
                    </b-button>
                  </router-link>
                </b-col>
              </b-row>
            </div>

            <div class="container mb-2">
              <b-row>
                <b-col
                  cols="4"
                  class="text-center mb-1 font-weight-bolder"
                >
                  <router-link :to="{ name: 'lottery' }">
                    <b-avatar
                      variant="secondary"
                      size="45"
                      style="border: 2px solid #accb32"
                    >
                      <i class="fad fa-sort-numeric-up fa-2x icon-l" />
                    </b-avatar><br>
                    แทงหวย
                  </router-link>
                </b-col>

                <b-col
                  cols="4"
                  class="text-center mb-1 font-weight-bolder"
                >
                  <b-avatar
                    variant="secondary"
                    size="45"
                    style="border: 2px solid #accb32"
                  >
                    <i class="fad fa-bookmark fa-2x icon-l" /></b-avatar><br>
                  กิจกรรม
                </b-col>

                <b-col
                  cols="4"
                  class="text-center mb-1 font-weight-bolder"
                >
                  <router-link :to="{ name: 'invite-friend' }">
                    <b-avatar
                      variant="secondary"
                      size="45"
                      style="border: 2px solid #accb32"
                    >
                      <i class="fad fa-handshake-alt fa-2x icon-l" /> </b-avatar><br>
                    แนะนำเพื่อน
                  </router-link>
                </b-col>

                <b-col
                  cols="4"
                  class="text-center mb-1 font-weight-bolder"
                >
                  <router-link :to="{ name: 'status' }">
                    <b-avatar
                      variant="secondary"
                      size="45"
                      style="border: 2px solid #accb32"
                    >
                      <i class="fad fa-exchange fa-2x icon-l" /> </b-avatar><br>
                    รายการฝาก / ถอน
                  </router-link>
                </b-col>

                <b-col
                  cols="4"
                  class="text-center mb-1 font-weight-bolder"
                >
                  <router-link :to="{ name: 'list-select' }">
                    <b-avatar
                      variant="secondary"
                      size="45"
                      style="border: 2px solid #accb32"
                    >
                      <i class="fad fa-list-ol fa-2x icon-l" /> </b-avatar><br>
                    รายการโพย
                  </router-link>
                </b-col>

                <b-col
                  cols="4"
                  class="text-center mb-1 font-weight-bolder"
                >
                  <a href="https://news.sanook.com/lotto/">
                    <b-avatar
                      variant="secondary"
                      size="45"
                      style="border: 2px solid #accb32"
                    >
                      <i
                        class="fad fa-calendar-check fa-2x icon-l"
                      /> </b-avatar><br>
                    ตรวจหวย
                  </a>
                </b-col>
              </b-row>
            </div>
          </div>

          <!-- Lotto THAI -->
          <div class="header-bg-content mb-2">
            <!-- พิเศษเฉพาะ-->
            <div class="container">
              <div>&nbsp;</div>
              <div class="lotto text-center">
                <div class="mb-1">
                  <img
                    src="th.svg"
                    alt=""
                    height="25"
                    class="img-country"
                  >
                  <span class="YrVDG">
                    ผลสลากกินแบ่งรัฐบาล งวดวันที่ {{ Result.THG.CloseDateName }}
                  </span>
                </div>
                <b-row>
                  <b-col
                    lg="6"
                    cols="6"
                  >
                    <h3>{{ Result.THG.U3 }}</h3>
                    <small>รางวัล 3 ตัวบน</small>
                  </b-col>
                  <b-col
                    lg="6"
                    cols="6"
                  >
                    <h3>{{ Result.THG.U2 }}</h3>
                    <small>รางวัล 2 ตัวบน</small>
                  </b-col>

                  <b-col
                    lg="6"
                    cols="12"
                  >
                    <h3>
                      {{ Result.THG.F3N1 }} {{ Result.THG.F3N2 }}
                      {{ Result.THG.B3N1 }} {{ Result.THG.B3N2 }}
                    </h3>
                    <small>รางวัล 3 ตัวล่าง</small>
                  </b-col>

                  <b-col
                    lg="6"
                    cols="12"
                  >
                    <h3>{{ Result.THG.D2 }}</h3>
                    <small>รางวัล 2 ตัวล่าง</small>
                  </b-col>
                </b-row>
              </div>

              <b-row class="mt-2 text-center">
                <b-col
                  lg="6"
                  cols="12"
                  class="p-0"
                >
                  <!-- Lotto THAI -->
                  <div class="header-bg-content mb-2">
                    <!-- พิเศษเฉพาะ-->
                    <div class="container">
                      <div class="lotto">
                        <div class="mb-1">
                          <img
                            src="https://www.เปิดสอบราชการ.com/img/articles/158634671920200416_154512.png"
                            alt=""
                            class="img-country"
                            height="25"
                          >
                          <span class="YrVDG"> หวย ออมสิน 16/12/2564 </span>
                        </div>
                        <b-row>
                          <b-col
                            lg="6"
                            cols="6"
                          >
                            <h3>xxx</h3>
                            <small>3ตัวบน</small>
                          </b-col>

                          <b-col
                            lg="6"
                            cols="6"
                          >
                            <h3>xx</h3>
                            <small>2ตัวล่าง</small>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <!-- พิเศษเฉพาะ -->
                  </div>
                  <!-- End Lotto THAI -->
                </b-col>

                <b-col
                  lg="6"
                  cols="12"
                  class="p-0"
                >
                  <!-- Lotto THAI -->
                  <div class="header-bg-content mb-2">
                    <!-- พิเศษเฉพาะ-->
                    <div class="container">
                      <div class="lotto">
                        <div class="mb-1">
                          <img
                            src="https://www.tode365.com/wp-content/uploads/2021/08/%E0%B8%98%E0%B8%81%E0%B8%AA.png"
                            alt=""
                            height="25"
                          >
                          <span class="YrVDG"> หวย ธกส. 16/12/2564 </span>
                        </div>
                        <b-row>
                          <b-col
                            lg="6"
                            cols="6"
                          >
                            <h3>xxx</h3>
                            <small>3ตัวบน</small>
                          </b-col>

                          <b-col
                            lg="6"
                            cols="6"
                          >
                            <h3>xx</h3>
                            <small>2ตัวล่าง</small>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <!-- พิเศษเฉพาะ -->
                  </div>
                  <!-- End Lotto THAI -->
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col
                  lg="12"
                  cols="12"
                  class="p-0"
                >
                  <!-- Lotto THAI -->
                  <div class="header-bg-content mb-2">
                    <!-- พิเศษเฉพาะ-->
                    <div class="container">
                      <div class="lotto">
                        <div class="mb-1">
                          <img
                            src="/th.svg"
                            alt=""
                            height="25"
                            class="img-country"
                          >
                          <span class="YrVDG"> หวยหุ้นไทย 2021-12-25 </span>
                        </div>
                        <b-row>
                          <b-col cols="12">
                            <b-table
                              striped
                              responsive
                              :per-page="perPage"
                              :current-page="currentPage"
                              :items="items"
                              :fields="fields"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :sort-direction="sortDirection"
                              :filter="filter"
                              :filter-included-fields="filterOn"
                              @filtered="onFiltered"
                            >
                              <template #cell(avatar)="data">
                                <b-avatar :src="data.value" />
                              </template>

                              <template #cell(status)="data">
                                <b-badge :variant="status[1][data.value]">
                                  {{ status[0][data.value] }}
                                </b-badge>
                              </template>
                            </b-table>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <!-- พิเศษเฉพาะ -->
                  </div>
                  <!-- End Lotto THAI -->
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col
                  lg="12"
                  cols="12"
                  class="p-0"
                >
                  <!-- Lotto THAI -->
                  <div class="header-bg-content mb-2">
                    <!-- พิเศษเฉพาะ-->
                    <div class="container">
                      <div class="lotto">
                        <div class="mb-1">
                          <i
                            class="fas fa-flag"
                            style="color: #ff0000"
                          />
                          <span class="YrVDG">
                            หวยหุ้นต่างประเทศ 2021-12-25
                          </span>
                        </div>
                        <b-row>
                          <b-col cols="12">
                            <b-table
                              striped
                              responsive
                              :per-page="perPage"
                              :current-page="currentPage"
                              :items="items"
                              :fields="fields"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :sort-direction="sortDirection"
                              :filter="filter"
                              :filter-included-fields="filterOn"
                              @filtered="onFiltered"
                            >
                              <template #cell(avatar)="data">
                                <b-avatar :src="data.value" />
                              </template>

                              <template #cell(status)="data">
                                <b-badge :variant="status[1][data.value]">
                                  {{ status[0][data.value] }}
                                </b-badge>
                              </template>
                            </b-table>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <!-- พิเศษเฉพาะ -->
                  </div>
                  <!-- End Lotto THAI -->
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col
                  lg="12"
                  cols="12"
                  class="p-0"
                >
                  <!-- Lotto THAI -->
                  <div class="header-bg-content mb-2">
                    <!-- พิเศษเฉพาะ-->
                    <div class="container">
                      <div class="lotto">
                        <div class="mb-1">
                          <i
                            class="fas fa-crown"
                            style="color: #fbc02d"
                          />
                          <span class="YrVDG"> ซุปเปอร์ยี่กี (รอบล่าสุด) </span>
                        </div>
                        <b-row>
                          <b-col cols="12">
                            <b-table
                              striped
                              responsive
                              :per-page="perPage"
                              :current-page="currentPage"
                              :items="items"
                              :fields="fields"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :sort-direction="sortDirection"
                              :filter="filter"
                              :filter-included-fields="filterOn"
                              @filtered="onFiltered"
                            >
                              <template #cell(avatar)="data">
                                <b-avatar :src="data.value" />
                              </template>

                              <template #cell(status)="data">
                                <b-badge :variant="status[1][data.value]">
                                  {{ status[0][data.value] }}
                                </b-badge>
                              </template>
                            </b-table>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <!-- พิเศษเฉพาะ -->
                  </div>
                  <!-- End Lotto THAI -->
                </b-col>
              </b-row>
            </div>
            <!-- พิเศษเฉพาะ -->
          </div>
          <!-- End Lotto THAI -->
        </b-col>
        <b-col
          md="3"
          class="p-0"
        />
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  // BCarousel,
  // BCarouselSlide,
} from 'bootstrap-vue'
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import HomeBanner from './HomeBanner.vue'
// import HomeSponsor from './HomeSponsor.vue'
import 'swiper/css/swiper.css'

export default {
  components: {
    BButton,
    // HomeBanner,
    // HomeSponsor,
    BRow,
    BCol,
    BTable,
    BAvatar,
    BBadge,
    // BCarousel,
    // BCarouselSlide,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      /* eslint-disable global-require */
      swiperData: [
        { img: require('@/assets/images/salon/1.jpg') },
        { img: require('@/assets/images/salon/2.jpg') },
        { img: require('@/assets/images/salon/3.jpg') },
        { img: require('@/assets/images/salon/4.jpg') },
        { img: require('@/assets/images/salon/5.jpg') },
        { img: require('@/assets/images/salon/6.jpg') },
      ],
      /* eslint-disable global-require */

      swiperOptions: {
        slidesPerView: 2,
        spaceBetween: 50,
        pagination: {
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        },
      },
      fields: [
        { key: 'round', label: 'รอบ' },
        { key: '3', label: '3ตัวบน' },
        { key: '2', label: '2ตัวล่าง' },
      ],
      items: [
        {
          round: 'หุ้นไทยเช้า',
          3: 'xxx',
          2: 'xx',
        },
        {
          round: 'หุ้นไทยเที่ยง',
          3: 'xxx',
          2: 'xx',
        },
        {
          round: 'หุ้นไทยบ่าย',
          3: 'xxx',
          2: 'xx',
        },
        {
          round: 'หุ้นไทยเย็น',
          3: 'xxx',
          2: 'xx',
        },
      ],
      Result: {
        THG: {
          CloseDateName: '-',
          R1: '-',
          U3: '-',
          U2: '-',
          F3N1: '-',
          F3N2: '-',
          B3N1: '-',
          B3N2: '-',
          D2: '-',
        },
      },
    }
  },
  async mounted() {
    const ReThg = await this.GetResult('THG', 'THG')
    if (ReThg) {
      this.Result.THG.CloseDateName = ReThg.RoundData.CloseDateName
      this.Result.THG.R1 = ReThg.R1
      this.Result.THG.U3 = ReThg.U3
      this.Result.THG.U2 = ReThg.U2
      this.Result.THG.F3N1 = ReThg.F3N1
      this.Result.THG.F3N2 = ReThg.F3N2
      this.Result.THG.B3N1 = ReThg.B3N1
      this.Result.THG.B3N2 = ReThg.B3N2
      this.Result.THG.D2 = ReThg.D2
    }
  },
  methods: {
    async ShowWallet() {
      try {
        const { data: response } = await this.$http.get(
          'https://api.ma5lotto.com/api/wallet/show',
        )
        if (response && response.success === true) {
          const Main = response.WalletData.MainWallet
          this.$store.state.appConfig.UserData.MainWallet = Main.balance
        } else {
          this.$store.state.appConfig.UserData.MainWallet = 0
        }
      } catch (err) {
        console.log(err)
      }
    },
    // eslint-disable-next-line consistent-return
    async GetResult(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.ma5lotto.com/api/result/showlast',
          { params },
        )

        if (ResData.success) {
          return ResData.mes
        }
        return null
      } catch (e) {
        // console.log(e)
        return null
      }
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped>
.vhgf {
  margin-top: -5px;
  height: 40px;
  color: rgb(253, 253, 253);
  background-color: #7c7c7c;
}
.footers {
  margin-top: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #111;
  height: auto;
  width: 100vw;
  padding-top: 40px;
  color: #fff;
}
.img-country {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.balance {
  /* border: 0.1px solid #accb32; */
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  background-color: #414141;
  background-image: url(https://www.lotto4321.com/_nuxt/img/bg-credit.96c4543.png);
  /* background-position: right right; */
  /* border-radius: 10px; */
  background-size: cover;
  position: relative;
  z-index: unset;
}
body,
.txt-size {
  font-size: 1.2rem;
  color: #000;
}
html {
  padding: 0;
  margin: 0;
}
.img-mark {
  border-radius: 7px;
}
h3 {
  font-size: 1.8rem;
  font-weight: bold;
}
.header-bg {
  /* background: #fff url('/bgs.jpeg') repeat-y center center; */
  background-size: cover;
  padding: 4px;
}
/* .header-bg-content {
  background-color: #ffffff;
} */
.YrVDG {
  font-size: 1.125rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(36, 36, 36);
}
@media (max-width: 574px) {
  .YrVDG {
    font-size: 1.1rem;
    font-weight: 700;
    color: #000;
  }
}
.text-card {
  font-weight: bold;
  color: #000;
  margin-top: 5px;
}
.container-spon {
  margin: auto;
  max-width: 640px;
}

.bg-balance {
  padding: 12px;
  /* border: 1px solid #accb32; */
  /* border-radius: 10px; */
  /* box-shadow: 0px 3px 10px rgb(255, 110, 110); */
}

.bg-balance2 {
  border: 1px solid #accb32;
  border-radius: 10px;
  background-image: url(/images/bg-header.svg);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}

.btn-menu {
  border: 1px solid #accb32;
  padding: 15px;
  /* border-radius: 10px; */
  color: #000;
  /* background-color: #accb32; */
}

.btn-menu2 {
  padding: 10px;
  color: rgb(0, 0, 0);
  background: #accb32;
}
.icon-l {
  color: #ffcc00;
}

.lotto {
  /* text-align: center; */
  border: 1px solid rgb(225, 225, 225);
  border-radius: 5px;
  padding: 0.3rem;
}
a {
  color: rgb(0, 0, 0);
  cursor: pointer;
}
</style>
